.card_list_container {
  padding: 10px;
  padding-left: 10%;
  padding-right: 10%;
}
.card_list_items {
  margin-bottom: 50px;
  position: relative;
}
.card_list_items_details {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 10px;
}
.card_list_image {
  width: 30%;
}
.right_container {
  display: flex;
  width: 28%;
  flex-direction: column;
  align-items: flex-end;
}
.card_list_des_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40%;
}
.camera_icon {
  position: absolute;
  bottom: 10%;
  left: 26%;
}
.images_count {
  position: absolute;
  bottom: 20%;
  left: 25.5%;
}
.images_count_details {
  position: absolute;
  bottom: 7%;
  right: 7%;
}
.images_count_phone {
  position: absolute;
  bottom: 11%;
  right: 11%;
}
.camera_icon img {
  height: 30px !important;
  width: 30px !important;
  opacity: 0.5;
}
.card_list_image img {
  height: 100%;
  cursor: pointer;

  width: 100%;
}
.card_list_description {
}

.carflix {
  margin-top: 20px;
}
.card_list_description_img {
  display: flex;
}
.card_list_image_sub {
  display: flex;
  align-self: flex-end;

  justify-self: flex-end;
  width: 45%;
  height: 90%;
}
.card_list_image_sub video {
  height: 100%;
  width: 90%;

  cursor: pointer;
}

.card_list_image_sub img {
  height: 100%;
  width: 90%;

  cursor: pointer;
}
.card_list_price_container {
  display: flex;
}
.bottom_button {
  margin-top: 10px;
  display: flex;
}

.request_info {
  background-color: #ff8c00;
  width: fit-content;
  cursor: pointer;

  padding: 10px;
}
.view_details {
  margin-right: 10px;

  width: fit-content;
  cursor: pointer;
  padding: 10px;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
}
.view_details:hover {
  background-color: #ff8c00;
  color: #fff;
  transition: 0.3s;
}

.mobile_screen_display {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
}
.mobile_card_list_items {
  display: flex;
  flex-direction: column;
}
.video_thumbnail {
  opacity: 1;
  position: relative;
}

.video_thumbail_icon {
  position: absolute;
  left: 35%;
  bottom: 35%;
  color: #fff;
  opacity: 65%;
}

.mobile_card_image {
  width: 100%;
  position: relative;
  background-size: cover;
}
.mobile_card_image img {
  width: 100%;
}
.card_list_container {
  display: none;
}
.mobile_screen_display {
  display: block;
}
@media only screen and (min-width: 800px) {
  .card_list_container {
    display: block;
  }

  .mobile_screen_display {
    display: none;
  }
}
.mobile_screen_container {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.search_inventory {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  border: 1px solid #948e8e;
  background-color: #e5e5e5;
  align-items: center;
}
.search_inventory input {
  border: none;
  outline: none;
  font-family: "latoblack";
  background-color: #e5e5e5;

  font-size: 16px;
  font-weight: 600;
  padding: 10px;
  width: 90%;
}

@media only screen and (max-width: 1100px) {
  .card_list_image {
    width: 35%;
  }
  .right_container {
    width: 23%;
  }
  .card_list_des_container {
    width: 40%;
  }
  .camera_icon {
    left: 30%;
  }
  .video_thumbail_icon {
    position: absolute;
    left: 30%;
    bottom: 20%;
  }
}
@media only screen and (max-width: 950px) {
  .card_list_image {
    width: 40%;
  }
  .right_container {
    width: 26%;
  }
  .card_list_des_container {
    width: 32%;
  }
  .camera_icon {
    left: 35%;
  }
  .video_thumbail_icon img {
    height: 30px;
    width: 30px;
  }
}

.link {
  color: inherit;
  text-decoration: inherit;
}
@media only screen and (max-width: 799px) {
  .camera_icon {
    position: absolute;
    bottom: 15%;
    left: 90%;
  }
  .images_count {
    position: absolute;
    bottom: 22%;
    font-size: 13px;
    left: 22%;
  }
  .video_thumbail_icon img {
    height: 50px;
    width: 50px;
  }
  .video_thumbail_icon {
    position: absolute;
    left: 41%;
    bottom: 30%;
  }
  .bottom_button {
    display: flex;
    flex-direction: column;
  }
  .view_details {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

@media only screen and (max-width: 450px) {
  .camera_icon {
    position: absolute;
    bottom: 15%;
    left: 80%;
  }
  .video_thumbail_icon {
    position: absolute;
    left: 35%;
    bottom: 20%;
  }
  .video_thumbail_icon img {
    height: 40px;
    width: 40px;
  }
}

@media only screen and (max-width: 350px) {
  .camera_icon {
    position: absolute;
    bottom: 15%;
    left: 80%;
  }
}
