.inventory_details_container {
  padding: 10px;
  padding-left: 10%;
  padding-right: 10%;
}
.inventory_details {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.inventory_image_details {
  width: 50%;
}

.inventory_specification_details {
  display: flex;
  flex-direction: column;
  width: 48%;
}
.camera_icon_details {
  position: absolute;
  bottom: 8%;
  right: 6%;
}
.images_count_details {
  bottom: 20%;
  right: 20%;
}
.camera_icon_details img {
  height: 30px !important;
  width: 30px !important;
  opacity: 0.5;
}

.inventory_sub_image_group {
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
}
.inventory_sub_image {
  flex: 0.33;
}
.inventory_sub_image img {
  height: 100%;
  width: 100%;
}
.inventory_description {
  margin-top: 20px;
}
.additional_information {
  margin-top: 10px;
  padding: 10px;
  background-color: #dee8ea;

  background-image: url(/public/zigzag_pattern_white.svg);

  background-blend-mode: screen;
}

.additional_heading {
  border-left: 8px solid #ff8c00;
  padding-left: 10px;
}

.inventory_specification_list {
  margin-top: 10px;
  width: 70%;
  height: 100%;
  padding: 10px;
  background-color: #669acc;
  background-image: url(/public/zigzag_pattern_white.svg);

  background-blend-mode: screen;
}

.inventory_details_head {
  display: flex;
  justify-content: space-between;
}
.inventory_details_header {
  flex: 0.6;
}
.inventory_details_subheader {
  flex: 0.8;
}
.inventory_details_container {
  display: none;
}
@media only screen and (min-width: 800px) {
  .inventory_details_container {
    display: block;
  }
  .mobile_screen_display {
    display: none;
  }
}
@media only screen and (max-width: 800px) {
  .inventory_specification_list {
    width: 100%;
  }

  .additional_information {
    margin-left: -20px;
    margin-right: -20px;
  }

  .inventory_specification_details {
    width: auto;
    margin-right: -10px;
    margin-left: -20px;
  }
}

.control-dots {
  display: none !important;
}
