.request-info-container {
  display: flex;
  flex-direction: column;

  justify-content: center;
  padding: 10px;
}
.button_submit_dis {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;

  background-color: #dddddd;
}
.button_submit_dis button {
  background-color: #dddddd;
  border: none;
  outline: none;
  color: #666666;
  font-size: 20px;
}

.button_submit {
  margin-top: 10px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 100%;
  background-color: #666666;
}
.button_submit button {
  background-color: #666666;
  border: none;
  cursor: pointer;
  outline: none;
  color: #fff;
  font-size: 20px;

  width: 100%;
}
.scripts_home {
  display: flex;

  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5%;

  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.button_scripts {
  margin-top: 40px;
}
.scripts_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}
.button_scripts button {
  padding: 15px 90px 15px 90px;
  border: none;
  border-radius: 9px;
  color: #fff;
  background-color: #07bc0c;
  cursor: pointer;
}
.cancel_return {
  display: flex;
  background-color: #666666;
  padding: 20px;
  color: #fff;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

.g-recaptcha {
  width: 100%;

  border-radius: 4px;
  background-color: #f9f9f9;
  overflow: hidden;
}
.rc-anchor {
  width: 100% !important;
  height: 100% !important;
  border: none !important;
}

.rc-anchor-normal {
  background: #000 !important;
  color: #000 !important;
}
